import React from 'react'

import Layout from '../components/layout'
import Meta from '../components/meta'

const PrivacyPolicyPage = () => (
  <>
    <Meta
      title="Privacy Policy"
    />
    <Layout>
      <div className="typography-page limiter">
        <h2>PRIVACY POLICY</h2>
        <p>
          This Privacy Policy governs the manner in which ScreenTray ("we" or "us") collects, uses, maintains and
          discloses
          information collected from users (each, a "User") of the https://screentray.com/ website (the "Site") and
          ScreenTray app (the “App”).
        </p>
        <h3>COLLECTION OF INFORMATION</h3>
        <p>We collect the Personal Information you give us when you complete a transaction, including email address.</p>
        <p>
          We automatically collect certain kinds of non-personal information from you when you use the Site and the App,
          including device, browser type, operating system, referring or exit web pages, click patterns, Session ID, your
          computer's IP address, and metrics related to queries you run using the Services.
        </p>
        <h3>HOW YOUR INFORMATION IS USED</h3>
        <p>
          Except as provided herein, we collect and use your personal and non-personal information for internal purposes
          only or for gathering aggregate and anonymized data, and we do not sell, rent, or share personally identifiable
          information about you with other people or with nonaffiliated companies except as described below. We may use
          your information in the following ways:
        </p>
        <ul>
          <li>To communicate with you about important announcements, special offers and support.</li>
          <li>To provide you with and enhance the Services.</li>
          <li>
            To gather generic product, location and other contributed information to make reports based on aggregate
            anonymized data, which we may use internally, or which we may share with others for a variety of purposes,
            including for their own uses.
          </li>
          <li>To comply with court and governmental orders.</li>
        </ul>
        <h3>YOUR USER CONTENT</h3>
        <p>Your User Content will be treated as follows:</p>
        <ul>
          <li>
            We will never have access to your data in the cloud.
            The access token, which is used to upload screenshots, is stored locally on your computer.
          </li>
          <li>We will never have access to your screenshots. The App uploads them directly to your cloud.</li>
          <li>We will never send news, spam to your emails except important announcements, special offers and support.
          </li>
        </ul>
        <h3>CONSENT</h3>
        <p>
          When you provide us with personal information to complete a transaction, place an order, we imply that you
          consent to our collecting it and using it for that specific reason only.
        </p>
        <p>
          If we ask for your personal information for a secondary reason, like marketing, we will either ask you
          directly for your expressed consent, or provide you with an opportunity to say no.
        </p>
        <p>
          If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the
          continued collection, use or disclosure of your information, at anytime, by contacting us at
          {' '}<a href="mailto:support@screentray.com">support@screentray.com</a>
        </p>
        <h3>DISCLOSURE</h3>
        <p>
          We will not disclose your personal information unless we are required by law to do so or
          you violate our End User License Agreement.
        </p>
        <h3>THIRD-PARTY SERVICES</h3>
        <p>
          In general, the third-party providers used by us will only collect, use and disclose your information to
          the extent necessary to allow them to perform the services they provide to us.
        </p>
        <p>
          However, certain third-party service providers, such as payment gateways and other payment transaction
          processors,
          have their own privacy policies in respect to the information we are required to provide to them for
          your purchase-related transactions.
        </p>
        <p>
          For these providers, we recommend that you read their privacy policies so you can understand the manner
          in which your personal information will be handled by these providers.
        </p>
        <p>
          In particular, remember that certain providers may be located in or have facilities that are located
          in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that
          involves the services of a third-party service provider, then your information may become subject
          to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
        </p>
        <p>
          As an example, if you are located in Canada and your transaction is processed by a payment gateway
          located in the United States, then your personal information used in completing that transaction may be subject
          to disclosure under United States legislation, including the Patriot Act.
        </p>
        <p>
          Once you leave our website or are redirected to a third-party website or application, you are no longer governed
          by this Privacy Policy or our website’s Terms of Service.
        </p>
        <p>
          When you click on links on our website, they may direct you away from our site. We are not responsible for
          the privacy practices of other sites and encourage you to read their privacy statements.
        </p>
        <h3>SECURITY</h3>
        <p>
          To protect your personal information, we take reasonable precautions and follow industry best practices
          to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
        </p>
        <h3>COOKIES</h3>
        <p>
          Our website may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive
          for
          record-keeping purposes and sometimes to track information about them. User may choose to set their web browser
          to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site
          may not function properly.
        </p>
        <h3>AGE OF CONSENT</h3>
        <p>
          By using this site, you represent that you are at least the age of majority in your state or province of
          residence, or that you are the age of majority in your state or province of residence and you have given us
          your consent to allow any of your minor dependents to use this site.
        </p>
        <h3>CHANGES TO THIS PRIVACY POLICY</h3>
        <p>
          We reserve the right to modify this privacy policy at any time, so please review it frequently.
          Changes and clarifications will take effect immediately upon their posting on the website.
          If we make material changes to this policy, we will notify you here that it has been updated,
          so that you are aware of what information we collect, how we use it, and under what circumstances,
          if any, we use and/or disclose it.
        </p>
        <p>
          If we sell assets or are acquired by or merged with another entity, we may provide such entity customer
          information without obtaining your further consent. We may provide notice of such asset sales,
          acquisitions, or mergers on the Site.
        </p>
        <h3>QUESTIONS AND CONTACT INFORMATION</h3>
        <p>
          If you would like to: access, correct, amend or delete any personal information we have about you,
          register a complaint, or simply want more information contact our Privacy Compliance Officer at
          {' '}<a href="mailto:support@screentray.com">support@screentray.com</a>
        </p>
        <p>Last updated on Mar 1, 2019</p>
      </div>
    </Layout>
  </>
)

export default PrivacyPolicyPage
